<template>
    <div slot="header">
      <h5 class="text-green-dark font-bold my-3 mx-2">
        Average dose (mL) of Filler Treatments
      </h5>
      <vs-tabs v-model="active" class="insightsTabs">
        <vs-tab label="By Brand">
          <BrandML></BrandML>
        </vs-tab>
        <vs-tab label="By Nurse">
          <BrandML byNurse :isStacked="true"></BrandML>
        </vs-tab>
        <vs-tab label="By Clinic">
          <BrandML byClinic :isStacked="true"></BrandML>
        </vs-tab>
      </vs-tabs>
    </div>
</template>

<script>
import BrandML from "../../components/insights/products/BrandmL.vue";

export default {
  components: {
    BrandML,
  },
  props: {},
  data() {
    return {
      active: 0,
    }
  }
}
</script>

<style>
  .insightsTabs .vs-tabs--ul {
    overflow: auto
  } 
  .insightsTabs .vs-tabs--li button.vs-tabs--btn {
    padding: .5rem 1rem;
  }
</style>
