<template>
    <div slot="header">
      <h5 class="title font-bold my-3 mx-2">
        Brand share of total appointments
      </h5>
      <vs-tabs v-model="active" class="insightsTabs">
        <vs-tab label="By Treatment Area">
          <TreatmentAreaByBrand></TreatmentAreaByBrand>
        </vs-tab>
        <vs-tab label="By Nurse">
          <TreatmentAreaByBrand byNurse></TreatmentAreaByBrand>
        </vs-tab>
        <vs-tab label="By Clinic">
          <TreatmentAreaByBrand byClinic></TreatmentAreaByBrand>
        </vs-tab>
      </vs-tabs>
    </div>
</template>



<script>
import TreatmentAreaByBrand from "../../components/insights/products/TreatmentAreaByBrand.vue";

export default {
  components: {
    TreatmentAreaByBrand,
  },
  props: {
    selected: {
      type: Object,
    },
  },
  data() {
    return {
      active: 0,
    }
  }
}
</script>

<style>
  .insightsTabs .vs-tabs--ul {
    overflow: auto
  } 
  .insightsTabs .vs-tabs--li button.vs-tabs--btn {
    padding: .5rem 1rem;
  }
</style>
