<template>
  <vs-row vs-w="12">
      <GlobalFilters 
        :categories="categories"
        :subCategories="subCategories"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        @updateSelectedCategory="selectedCategory = $event"
        @updateSelectedSubCategory="selectedSubCategory = $event"
        :selected="selected"
        @updateFilters="selected = $event"
        :page="'brand-ml'"
        :isAllClinics="isAllClinics"
        :urlType="urlType"
        :productType="'filler'"
      />
      <!-- Chart -->
      <!-- <div class="w-full m-5" v-if="urlType === 'Supplier' && !byNurse && !byClinic">
        <label for>All Clinics</label>
        <vs-switch
          class="m-2"
          v-model="isAllClinics"
        />
      </div> -->
      <vs-col vs-w="12">
        <vs-row
          v-if="allTotalCount"
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="right"
          vs-w="12"
        >Total count of dose (mL): {{ Number.parseFloat(allTotalCount).toFixed(2) }}
        </vs-row>
        <BarChart 
          :chartData="chartData"
          :chartOptions="chartOptions"
        />
      </vs-col>
    </vs-row>
</template>

<script>
import BarChart from '../charts/BarChart.vue'
import GlobalFilters from "../common/GlobalFilters"
import {
  filterCategories
} from "../common/GlobalFilters/filterConstant.js"
import { mapActions, mapMutations, mapState } from "vuex";
import utils from '@/assets/utils'
import helper from "../../../superAdmin/insights/helper";

export default {
  components: {
    BarChart,
    GlobalFilters
  },
  props: {
    tabs: Array,
    byNurse: Boolean,
    byClinic: Boolean,
    isStacked: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState('analytics', ['chartData', 'FILTERS_SELECTED']),
    allTotalCount(){
      const chartDataSets = this.chartData.datasets ? this.chartData.datasets[0] : null
      let count = 0;
      if(chartDataSets && chartDataSets.totalCount) {
        chartDataSets.totalCount.forEach(item => {
            count += item;
        });
      }
      return count
    }
  },
  data() {
    return {
      categories: [
        filterCategories.ORGANIZATIONS,
        filterCategories.CLINICS,
        filterCategories.NURSE,
        filterCategories.PATIENTS,
        filterCategories.TREATMENTS,
      ],
      selectedCategory: {},
      subCategories: [],
      selectedSubCategory: {},
      selected: {},
      static: {
        temp_brand: [],
      },
      // Chart data
      // chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: this.byNurse || this.byClinic ? true : false,
            position: 'top',
            labels: {
              boxWidth: 12
            }
          },
          title: {
            display: true,
            text: ''
          },
          tooltip: {
            callbacks: {
              footer: function(context) {
                const contextItem = context[0]
                const totalCount = contextItem.dataset.totalCount 
                let totalCountLabel = "";

                if(totalCount && totalCount.length){
                  totalCountLabel = 'Count: ' + totalCount[contextItem.dataIndex];
                }
                return totalCountLabel;
              },
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Average ml.'
            },
            min: 0,
            stacked: this.isStacked,
          },
          x: {
            title: {
              display: true,
              text: this.byNurse ? 'Nurse' : this.byClinic ? 'Clinic' : 'Brand',
            },
            grid : {
              display : false
            },
            stacked: this.isStacked,
            ticks: {
              font: {
                size: 10
              },
              maxRotation: this.byNurse || this.byClinic ? 90 : 0,
              minRotation: this.byNurse || this.byClinic ? 90 : 0,
            }
          }
        }
      },
      urlType: "",
      orgId: "",
      isAllClinics: false,
    }
  },
  methods:{
    ...mapActions("analytics", ["fetchBrands"]),
    ...mapMutations("analytics", ["MUTATE_CHART_DATA", "MUTATE_TABLE_DATA", "MUTATE_TABLE_HEADERS"]),
    fetchBrandsChartData() {
      
      let payload = helper.generateAnalyticsAPIParameters(this.selected)
      payload.is_nurse = this.byNurse;
      payload.type = 'filler'

      if(!this.isAllClinics){
        if(this.selected.brand && this.selected.brand.length && this.static.temp_brand[0] === 0){
          payload.brand = this.selected.brand
        } else {
          payload.brand = this.static.temp_brand
        }
      }

      if (["OrgOwner", "SuperAdmin"].includes(this.urlType)){
        payload.brand = this.selected.brand
      }

      if(this.byClinic){
        payload = {
          ...payload,
          is_clinic: this.byClinic,
        }
      }
      if(!["SuperAdmin", "Supplier"].includes(this.urlType)){
        payload = {
          ...payload,
          org: [this.orgId],
        }
      }
      this.fetchBrands(payload).then((res) => {
        const headers= res.data.chartData.datasets.map((data)=>data.label)
        const tableHeaders = [this.byNurse?'Nurse': this.byClinic ? "Clinic":"Brand",
        ..._.pullAll(this.byNurse || this.byClinic? headers : [null],[null])];
        if(!this.byNurse && !this.byClinic){
          tableHeaders.push("Average mL.")
        }
        this.MUTATE_TABLE_HEADERS(tableHeaders)
        this.MUTATE_CHART_DATA(res.data.chartData)
        

        const { labels, datasets } = res.data.chartData;
        const tableData = labels.map((label, index) => {
          return {
            brand: label,
            values:this.getvalues(index,datasets),
            avg: (datasets[0].data[index]).toFixed(2),
          }
        })
        this.MUTATE_TABLE_DATA(tableData)
      });
    },
    getvalues(index,datasets){
      const data = datasets.map( brand => {
        const temp={}
          return brand.data[index].toFixed(2)
        } )
      return data
    }
  },
  created() {
    this.selected = _.cloneDeep(this.FILTERS_SELECTED)
    this.MUTATE_TABLE_DATA([])
    this.MUTATE_CHART_DATA({})
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      this.static.temp_brand = userType.brands;
    } else if(this.urlType == "Supplier" || this.urlType == "OrgOwner"){
      this.selected.brand = []
      this.static.temp_brand[0] = 0
    }
  },
  watch: {
    selected: {
      handler() {
        this.fetchBrandsChartData();
      },
      deep: true,
    },
    isAllClinics: {
      handler() {
        this.fetchBrandsChartData();
      }
    }
  }
}
</script>

<style ></style>