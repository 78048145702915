<template>
  <div slot="header">
    <h5 class="text-green-dark font-bold my-3 mx-2">
      Brand share (%) of Treatments
    </h5>
    <vs-tabs v-model="active" class="insightsTabs">
      <vs-tab label="By Brands">
        <PercentageTreatments @updateIsAllClinics="isAllClinics=$event" :isAllClinics="isAllClinics"></PercentageTreatments>
      </vs-tab>
      <vs-tab id="byNurseTab" label="By Nurse">
        <PercentageTreatments byNurse :isStacked="true"></PercentageTreatments>
      </vs-tab>
      <vs-tab id="byClinicTab" label="By Clinic">
        <PercentageTreatments byClinic :isStacked="true"></PercentageTreatments>
      </vs-tab>
      <vs-tab label="Over Time">
        <Overtime byOverTime ></Overtime>
      </vs-tab>
    </vs-tabs>
  </div>
</template>


<script>
import PercentageTreatments from "../../components/insights/products/PercentageTreatments.vue";
import Overtime from "../../components/insights/products/Overtime.vue";
export default {
  components: {
    PercentageTreatments,
    Overtime
  },
  props: {},
  data() {
    return {
      active: 0,
      urlType: "",
      isAllClinics: false
    }
  },
  methods: {},
  watch: {
    isAllClinics(value) {
      if(value) {
        document.getElementById('byNurseTab').classList.add("hide-tab-class");
        document.getElementById('byClinicTab').classList.add("hide-tab-class");
      } else {
        const allHiddenElements = document.querySelectorAll('.hide-tab-class');
        allHiddenElements.forEach((element) => {
          element.classList.remove('hide-tab-class');
        });
      }
    }
  },
  created() {
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
  },
}
</script>

<style>
  .hide-tab-class{
    display: none !important;
  }
  .insightsTabs .vs-tabs--ul {
    overflow: auto
  } 
  .insightsTabs .vs-tabs--li button.vs-tabs--btn {
    padding: .5rem 1rem;
  }
</style>
