<template>
  <vs-row vs-w="12">
      <GlobalFilters 
        :categories="categories"
        :subCategories="subCategories"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        @updateSelectedCategory="selectedCategory = $event"
        @updateSelectedSubCategory="selectedSubCategory = $event"
        :selected="selected"
        @updateFilters="selected = $event"
        :page="'brand-unit'"
        :isAllClinics="isAllClinics"
        :urlType="urlType"
        :productType="'toxin'"
      />
      <!-- Chart -->
      <!-- <div class=" m-5" v-if="urlType === 'Supplier' && !byNurse && !byClinic">
        <label for>All Clinics</label>
        <vs-switch
          class="m-2"
          v-model="isAllClinics"
        />
      </div> -->

      <div class="grid grid-cols-1 md:grid-cols-2 gap-y-4 md: gap-y-0 items-center w-full my-4">

         <div class="w-full md:col-start-1">
          <div class="flex items-center justify-between space-x-4 bg-gray-100 py-3 px-4 rounded-lg w-full md:w-6/12">
            <label class="optionslabel">Standardised</label>
            <vs-switch v-model="isStandardized" />
          </div>
        </div>

        <p v-if="allTotalCount" class="optionslabel w-full md:col-start-2 text-center md:text-right">Total count of treatments: {{ Number.parseFloat(allTotalCount).toFixed(0) }}</p>
      
      </div>

      <BarChart 
        :chartData="chartData"
        :chartOptions="chartOptions"
        
      />

    </vs-row>
</template>

<script>
import BarChart from '../charts/BarChart.vue'
import GlobalFilters from "../common/GlobalFilters"
import {
  filterCategories
} from "../common/GlobalFilters/filterConstant.js"
import { mapActions, mapMutations, mapState } from "vuex";
import utils from '@/assets/utils'
import helper from "../../../superAdmin/insights/helper";

export default {
  components: {
    BarChart,
    GlobalFilters
  },
  props: {
    tabs: Array,
    byNurse: Boolean,
    byClinic: Boolean,
    isStacked: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState('analytics', ['chartData', 'FILTERS_SELECTED']),
    allTotalCount(){      
      let count = 0;
      if(this.byNurse || this.byClinic) {
        const chartDataSets = this.chartData.datasets ? this.chartData.datasets : null
        if(chartDataSets) {
          chartDataSets.forEach(dataSet => {
            dataSet.totalCount.forEach(val => {
                count += val;
            });
          });
        }
      } else {
        const chartDataSets = this.chartData.datasets ? this.chartData.datasets[0] : null
        if(chartDataSets && chartDataSets.totalCount) {
          chartDataSets.totalCount.forEach(val => {
              count += val;
          });
        }
      }

      return count
    }
  },
  data() {
    return {
      categories: [
        filterCategories.ORGANIZATIONS,
        filterCategories.CLINICS,
        filterCategories.NURSE,
        filterCategories.PATIENTS,
        filterCategories.TREATMENTS,
      ],
      selectedCategory: {},
      subCategories: [],
      selectedSubCategory: {},
      selected: {},
      static: {
        temp_brand: [],
      },
      // Chart data
      // chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: this.byNurse || this.byClinic ? true : false,
            position: 'top',
            labels: {
              boxWidth: 12
            }
          },
          title: {
            display: true,
            text: ''
          },
          tooltip: {
            callbacks: {
              footer: function(context) {
                const contextItem = context[0]
                const totalCount = contextItem.dataset.totalCount 
                let totalCountLabel = "";

                if(totalCount){
                  if(totalCount.length){
                    totalCountLabel = 'Count: ' + totalCount[contextItem.dataIndex];
                  } else {
                    totalCountLabel = 'Count: ' + totalCount;
                  }
                }
                return totalCountLabel;
              },
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Average unit'
            },
            min: 0,
            stacked: this.isStacked,
          },
          x: {
            title: {
              display: true,
              text: this.byNurse ? 'Nurse' : this.byClinic ? 'Clinic' : 'Brand',
            },
            grid : {
              display : false
            },
            stacked: this.isStacked,
            ticks: {
              font: {
                size: 10
              },
              maxRotation: this.byNurse || this.byClinic ? 90 : 0,
              minRotation: this.byNurse || this.byClinic ? 90 : 0,
            }
          }
        }
      },
      urlType: "",
      orgId: "",
      isStandardized: false,
      isAllClinics: false,
    }
  },
  methods:{
    ...mapActions("analytics", ["fetchBrands"]),
    ...mapMutations("analytics", ["MUTATE_CHART_DATA", "MUTATE_TABLE_DATA", "MUTATE_TABLE_HEADERS"]),
    fetchBrandsChartData() {
      
      let payload = helper.generateAnalyticsAPIParameters(this.selected)
      payload.is_nurse = this.byNurse;
      payload.type = 'toxin'
      payload.is_standard = this.isStandardized
      
      if(!this.isAllClinics){
        if(this.selected.brand && this.selected.brand.length && this.static.temp_brand[0] === 0){
          payload.brand = this.selected.brand
        } else {
          payload.brand = this.static.temp_brand
        }
      }

      if (["OrgOwner", "SuperAdmin"].includes(this.urlType)){
        // payload.brand = []
        payload.brand = this.selected.brand
      }

      if(this.byClinic){
        payload = {
          ...payload,
          is_clinic: this.byClinic,
        }
      }
      if(!["SuperAdmin", "Supplier"].includes(this.urlType)){
        payload = {
          ...payload,
          org: [this.orgId],
        }
      }
      this.fetchBrands(payload).then((res) => {
        const headers= res.data.chartData.datasets.map((data)=>data.label)
        const tableHeaders = [this.byNurse?'Nurse': this.byClinic ? "Clinic":"Brand",
        ..._.pullAll(this.byNurse || this.byClinic? headers : [null],[null])];
        if(!this.byNurse && !this.byClinic){
          tableHeaders.push("Unit")
        }
        this.MUTATE_TABLE_HEADERS(tableHeaders)

        this.MUTATE_CHART_DATA(res.data.chartData)

        const { labels, datasets } = res.data.chartData;
        const tableData = labels.map((label, index) => {
          return {
            brand: label,
            values:this.getvalues(index,datasets),
            unit: (datasets[0].data[index]).toFixed(2),
          }
        })
        this.MUTATE_TABLE_DATA(tableData)
      });
    },
    getvalues(index,datasets){
      const data = datasets.map( brand => {
        const temp={}
          return brand.data[index].toFixed(2)
        } )
      return data
    }
  },
  created() {
    this.selected = _.cloneDeep(this.FILTERS_SELECTED)
    this.MUTATE_TABLE_DATA([])
    this.MUTATE_CHART_DATA({})
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      this.static.temp_brand = userType.brands;
    } else if(this.urlType == "Supplier" || this.urlType == "OrgOwner"){
      this.selected.brand = []
      this.static.temp_brand[0] = 0
    }
  },
  watch: {
    selected: {
      handler() {
        this.fetchBrandsChartData();
      },
      deep: true,
    },
    isStandardized: {
      handler() {
        this.fetchBrandsChartData();
      }
    },
    isAllClinics: {
      handler() {
        this.fetchBrandsChartData();
      }
    }
  }
}
</script>

<style>
  .optionslabel {
    font-size: .85rem;
  }
</style>