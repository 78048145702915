<template>
  <vs-row vs-w="12">
      <GlobalFilters 
        :categories="categories"
        :subCategories="subCategories"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        @updateSelectedCategory="selectedCategory = $event"
        @updateSelectedSubCategory="selectedSubCategory = $event"
        :selected="selected"
        @updateFilters="selected = $event"
        :page="'treatment-areas-by-brand'"
        :isAllClinics="isAllClinics"
        :urlType="urlType"
      />
      
      <!-- Chart -->
      <!-- <div class="w-full m-5" v-if="urlType === 'Supplier' && !byNurse && !byClinic">
        <label for>All Clinics</label>
        <vs-switch
          class="m-2"
          v-model="isAllClinics"
        />
      </div> -->
    
      <div class="flex flex-col md:flex-row items-center justify-end space-y-4 md:space-y-0 w-full my-4">
        <p v-if="allTotalCount" class="optionslabel w-full md:w-1/2 text-center md:text-right">Total count of treatments: {{ Number.parseFloat(allTotalCount).toFixed(0) }}</p>
      </div>
      
      <BarChart
        :chartData="chartData"
        :chartOptions="chartOptions"
      />
        
    </vs-row>
</template>

<script>
import BarChart from '../charts/BarChart.vue'
import GlobalFilters from "../common/GlobalFilters"
import {
  filterCategories
} from "../common/GlobalFilters/filterConstant.js"
import { mapActions, mapMutations, mapState } from "vuex";
import utils from '@/assets/utils'
import helper from "../../../superAdmin/insights/helper";

export default {
  components: {
    BarChart,
    GlobalFilters
  },
  props: {
    tabs: Array,
    byNurse: Boolean, 
    byClinic: Boolean
  },
  computed: {
    ...mapState('analytics', ['chartData', 'FILTERS_SELECTED']),
    allTotalCount(){      
      let count = 0;
      const chartDataSets = this.chartData.datasets ? this.chartData.datasets : null
      if(chartDataSets) {
        chartDataSets.forEach(dataSet => {
          dataSet.data.forEach(val => {
              count += val;
          });
        });
      }

      return count
    }
  },
  data() {
    return {
      categories: [
        filterCategories.ORGANIZATIONS,
        filterCategories.CLINICS,
        filterCategories.NURSE,
        filterCategories.PATIENTS,
        filterCategories.TREATMENTS,
      ],
      selectedCategory: {},
      subCategories: [],
      selectedSubCategory: {},
      selected: {},
      static: {
        temp_brand: [],
      },
      // Chart data
      // chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              boxWidth: 12
            }
          },
          title: {
            display: true,
            text: ''
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Number of treatments'
            },
            min: 0,
            stacked: true,
          },
          x: {
            title: {
              display: true,
              text: this.byNurse ? 'Nurse' : this.byClinic ? 'Clinic' : 'Treatment Area'
            },
            grid : {
              display : false
            },
            stacked: true,
            ticks: {
               font: {
                size: 10
              },
              maxRotation: 90,
              minRotation: 90,
            }
          }
        }
      },
      urlType: "",
      orgId: "",
      isAllClinics: false,
    }
  },
  methods:{
    ...mapActions("analytics", ["fetchTreatmentAreasByBrand"]),
    ...mapMutations("analytics", ["MUTATE_CHART_DATA", "MUTATE_TABLE_DATA", "MUTATE_TABLE_HEADERS"]),
    fetchTreatmentAreasByBrandChartData() {

      let payload = helper.generateAnalyticsAPIParameters(this.selected)
      payload.is_nurse = this.byNurse;

      if(!this.isAllClinics){
        if(this.selected.brand && this.selected.brand.length && this.static.temp_brand[0] === 0){
          payload.brand = this.selected.brand
        } else {
          payload.brand = this.static.temp_brand
        }
      }

      if (["OrgOwner", "SuperAdmin"].includes(this.urlType)){
        // payload.brand = []
        payload.brand = this.selected.brand
      }

      if (this.byClinic) {
        payload = {
          ...payload,
          is_clinic: this.byClinic,
        }
      }
      if(!["SuperAdmin", "Supplier"].includes(this.urlType)){
        payload = {
          ...payload,
          org: [this.orgId],
        }
      }
      this.fetchTreatmentAreasByBrand(payload).then((res) => {
        this.MUTATE_CHART_DATA(res.data.chartData)

        const { labels, datasets } = res.data.chartData;
        const brands = datasets.map((brand) => brand.label)
        const columns = [
          ...[this.byNurse?'Nurse': this.byClinic?'Clinic': 'Area'],
          ...brands
        ]
        this.MUTATE_TABLE_HEADERS(columns)

        const tableData = labels.map((label, i) => {
          let mainLabel = { Area: label }
          if(this.byNurse) {
            mainLabel = { Nurse: label }
          } else if (this.byClinic) {
            mainLabel = { Clinic: label }
          } 
          return brands.reduce((acc, _, ii) => ({
            ...acc,
            [datasets[ii].label]: datasets[ii].data[i]
          }), mainLabel)
        })
        
        this.MUTATE_TABLE_DATA(tableData)
      });
    },
  },
  created() {
    this.selected = _.cloneDeep(this.FILTERS_SELECTED)
    this.MUTATE_TABLE_DATA([])
    this.MUTATE_TABLE_HEADERS([])
    this.MUTATE_CHART_DATA({})
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      // this.selected.brand = userType.brands;
      this.static.temp_brand = userType.brands;
    } else if(this.urlType == "Supplier" || this.urlType == "OrgOwner"){
      this.selected.brand = []
      this.static.temp_brand[0] = 0
    }
  },
  watch: {
    selected: {
      handler() {
        this.fetchTreatmentAreasByBrandChartData();
      },
      deep: true,
    },
    isAllClinics: {
      handler() {
        this.fetchTreatmentAreasByBrandChartData();
      }
    }
  },
}
</script>
