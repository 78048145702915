<template>
  <div class="flex flex-col lg:flex-row lg:space-x-4">

    <!-- SideBar -->
    <vs-card class="flex-1 px-1" id="testID">

      <vs-button 
        color="dark" 
        type="flat"
        icon="arrow_back_ios"
        @click="goToInsights"
        class="p-0 lg:py-1 text-sm">
        Back to Insights
      </vs-button>

       <div class="mt-8 lg:mt-6">
        <p class="text-green-dark text-lg font-bold mb-2"> Products </p>
        <div
          class="flex items-center justify-between py-2 text-base cursor-pointer"
          @click="showBrand('brand')"
        >
          <span class="font-medium" style="color: rgba(62, 105, 92, 1)"
            >Brands
          </span>
          <vs-icon v-if="sideMenuOptions.brand" icon="expand_more"></vs-icon>
          <vs-icon v-else icon="chevron_right"></vs-icon>
        </div>
        <div v-if="sideMenuOptions.brand" class="flex flex-col space-y-1">
          <p 
            @click="showBrand('percent_treatment')"
            class="listHover px-4 py-2 cursor-pointer font-medium text-base"
            :class="sideMenuOptions.percent_treatment ? 'categoryActive' : 'bg-gray-100 rounded-lg'"> 
            Brand share (%) of Treatments 
          </p>

          <p 
            @click="showBrand('treatment_by_areas')"
            class="listHover px-4 py-2 cursor-pointer font-medium text-base"
            :class="sideMenuOptions.treatment_by_areas ? 'categoryActive' : 'bg-gray-100 rounded-lg'"> 
            Brand share of total appointments
          </p>

          <p 
            @click="showBrand('brand_ml')"
            class="listHover px-4 py-2 cursor-pointer font-medium text-base"
            :class="sideMenuOptions.brand_ml ? 'categoryActive' : 'bg-gray-100 rounded-lg'"> 
            Average dose (mL) of Filler Treatments
          </p>

          <p 
            @click="showBrand('brand_unit')"
            class="listHover px-4 py-2 cursor-pointer font-medium text-base"
            :class="sideMenuOptions.brand_unit ? 'categoryActive' : 'bg-gray-100 rounded-lg'"> 
            Average units of Toxin Treatments
          </p>

        </div>
      </div>

    </vs-card>
    <!-- End SideBar -->

    <vs-card class="w-full lg:w-9/12">
      <TreatmentAreaByBrand v-if="sideMenuOptions.treatment_by_areas"></TreatmentAreaByBrand>
      <BrandML v-else-if="sideMenuOptions.brand_ml"></BrandML>
      <BrandUnit v-else-if="sideMenuOptions.brand_unit"></BrandUnit>
      <PercentageTreatments v-else></PercentageTreatments>

      <!-- <div class="flex justify-end mt-2 mb-2 w-full">
        <vs-button class="w-full lg:w-auto" @click="handleExportToCSV">
          Export
        </vs-button>
      </div> -->

      <vs-table
        v-if="sideMenuOptions.percent_treatment || sideMenuOptions.brand_ml || sideMenuOptions.brand_unit"
        max-items="10" pagination
        :data="table.data"
        noDataText="No Data Available"
        :hoverFlat="true"
        :key="'main-'+tableKey"
        class="customtable w-full"
      >

        <template slot="thead">
          <vs-th v-for="(th, indexth) in table.headers" :key="indexth">
            {{ th }}
          </vs-th>
        </template>

        <template slot-scope="{ data }" >
          <vs-tr :key="indextr" v-for="(tr, indextr) in table.data">
            <template v-for="(value, name) in data[indextr]" >
              <template v-if="name==='brand'">
                <vs-td data-label="{ th }" :key="name" >
                {{ value }}
                </vs-td>
              </template>
              <template v-if="name==='values'">
                <vs-td data-label="{ th }" :key="index" v-for="(val, index) in value" >
                {{ val }}
                </vs-td>
              </template>
            </template>
          </vs-tr>
        </template>

      </vs-table>
      
      <vs-table 
        v-else
        max-items="10" pagination
        :data="table.data"
        noDataText="No Data Available"
        :hoverFlat="true"
        :key="'alt-'+tableKey"
        class="customtable w-full"
      >
        <template slot="thead">
          <vs-th v-for="(th, indexth) in table.headers" :key="indexth">
            {{ th }}
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in table.data">
            <vs-td data-label="{ th }" v-for="(value, name) in data[indextr]" :key="name">
              {{ value }}
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>

    </vs-card>
  </div>
</template>


<script>
import TreatmentAreaByBrand from './TreatmentAreaByBrand.vue';
import PercentageTreatments from './PercentageTreatments.vue';
import BrandML from './BrandmL.vue';
import BrandUnit from './BrandUnit.vue';
import { mapState, mapActions } from "vuex";
import utils from '@/assets/utils'
import moment from 'moment';

export default {
  components: {
    TreatmentAreaByBrand,
    PercentageTreatments,
    BrandML,
    BrandUnit
  },
  data() {
    return {
      tableKey: "",
      sideMenuOptions: {
        brand: true,
        percent_treatment: true,
        treatment_by_areas: false,
        brand_ml: false,
        brand_unit: false,
      },
      urlType: "",
      userbrands: ""
    }
  },
  methods: {
    ...mapActions("analytics", ["fetchAnalyticsFilters"]),
    goToInsights() {
      this.$router.push({ name: `${this.urlType}Insights` });
    },
    goToPage(value){
      if(value){
        this.$router.push({name: value})
      }
    },
    showBrand(value) {
      if (value === "brand") {
        this.sideMenuOptions["brand"] = Boolean(
          !this.sideMenuOptions["brand"]
        );
        if (!this.sideMenuOptions["brand"]) {
          Object.keys(this.sideMenuOptions).map((key) => {
            if (key !== 'brand') {
              this.sideMenuOptions[key] = false;
            }
          })
        }
        return
      }

      this.sideMenuOptions[value] = !this.sideMenuOptions[value]
      if (this.sideMenuOptions[value]) {
        Object.keys(this.sideMenuOptions).map((key) => {
          if (key !== 'brand' && key !== value) {
            this.sideMenuOptions[key] = false;
          }
        })
      }
    },
    getFilters() {
      let payload = {}
      const org_id = [localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)]

      if (!["SuperAdmin", "Supplier"].includes(this.urlType)) payload = {org_id}

      if(this.urlType === "Supplier" && this.userbrands) payload.brand_id = this.userbrands
      this.$vs.loading();
      this.fetchAnalyticsFilters(payload).then(() => this.$vs.loading.close()
      ).catch((err) => {
        console.error("##ERROR: ", err.message)
        this.$vs.loading.close();
      });
    },
    handleExportToCSV() {
      let csv = "\ufeff";
      let filename = 'share_of_treatments_'
      csv += this.table.headers.join(',') + "\n";

      this.table.data.forEach(row => {
        if (this.sideMenuOptions.percent_treatment) {
          csv += `${row.brand}`
          if(row.values.length > 1) {
            row.values.forEach(value => {
              csv += `,${value}`
            })
          } else {
            csv += `,${row.percent_treatment}`;
          }
          csv += `\n`;
        }
        if (this.sideMenuOptions.treatment_by_areas) {
          filename = 'share_of_total_appointments_'
          if (row.percent_treatment) {
            csv += `${row.brand},${row.percent_treatment}\n`;
          } else {
            const values = this.table.headers.map((header) => {
              return (typeof row[header] === 'string') ? row[header].replace(/,/g,' ') : row[header]
            }).join(',')
            csv += `${values}\n`;
          }
        }
        if (this.sideMenuOptions.brand_ml) {
          filename = 'avg_dose_ml_filler_'
          if (row.avg){
            csv += `${row.brand}`;
            if(row.values.length > 1) {
              row.values.forEach(value => {
                csv += `,${value}`
              })
            } else {
              csv += `,${row.avg}`
            }
            csv += `\n`;
          }
          if (row.percent_treatment){ 
            csv += `${row.brand},${row.percent_treatment}\n`;
          }
        }
        if (this.sideMenuOptions.brand_unit) {
          filename = 'avg_units_toxin_'
          if (row.unit) {
            csv += `${row.brand}`;
            if(row.values.length > 1) {
              row.values.forEach(value => {
                csv += `,${value}`
              })
            } else {
              csv += `,${row.unit}`
            }
            csv += `\n`;
          }
          if (row.percent_treatment) csv += `${row.brand},${row.percent_treatment}\n`;
        }
      });
      utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
    }
  },
  computed: {
    ...mapState('analytics', ['table']),
  },
  watch: {
    table: {
      handler() {
        const randNumber = Math.random().toString(16).slice(2)
        this.tableKey = randNumber
      },
      deep: true,
    },
  },
  created() {
    let userType = JSON.parse(
    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
      this.userbrands = userType.brands
    }else{
      this.urlType = "OrgOwner";
    }
    this.getFilters();
  },
  destroyed() {
    this.hideMarkerWidget();
  }
};
</script>

<style>
  .listHover:hover {
    background-color: rgba(111, 214, 198, 0.6);
    color: #3E695C;
    border-radius: 0.5em;
  }
  .categoryActive {
    background-color: #074230;
    color: white;
    border-radius: 0.5em;
  }
  .categoryActive span {
    color: white !important;
  }
  .customtable .vs-table--thead th {
      background: #f3faf8;
  }
  /* Responsive <table> */
  @media screen and (max-width: 600px) {
    .customtable .vs-table--content .vs-con-table .vs-con-tbody {
        border: none;
    }
    .customtable .vs-table--tbody-table {
        min-width: unset;
    }
    .customtable .vs-table--header {
      flex-direction: column;
    }
    .customtable .vs-table--thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      display: none !important;
    }
    .customtable .vs-table--tr {
      display: block;
      margin-bottom: .625em;
    }
    .customtable .vs-table--td {
      border-bottom: .05rem solid #ddd;
      display: block;
      font-size: 1rem;
      text-align: right;
    }
    .customtable .vs-table--td:first-child{
      min-width: 230px;
    }
    .customtable .vs-table--td::before {
      content: attr(data-label);
      float: left;
      font-weight: 600;
    }
    .customtable .vs-table--td:last-child {
      border-bottom: 0;
    }
  }
</style>
