<template>
  <vs-row vs-w="12">
      <GlobalFilters 
        :categories="categories"
        :subCategories="subCategories"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        @updateSelectedCategory="selectedCategory = $event"
        @updateSelectedSubCategory="selectedSubCategory = $event"
        :selected="selected"
        @updateFilters="selected = $event"
        :page="'percent-of-treatment'"
        :isOvertime="byOverTime"
        :urlType="urlType"
      />
      
      <!-- Chart -->
      <div class="flex flex-col md:flex-row items-center justify-end space-y-4 md:space-y-0 w-full my-4">
        <p v-if="allTotalCount" class="optionslabel w-full md:w-1/2 text-center md:text-right">Total count of treatments: {{ Number.parseFloat(allTotalCount).toFixed(0) }}</p>
      </div>

      <LineChart 
        :chartData="chartData"
        :chartOptions="chartOptions"
      />

    </vs-row>
</template>

<script>
import LineChart from '../charts/LineChart.vue'
import GlobalFilters from "../common/GlobalFilters"
import {
  filterCategories
} from "../common/GlobalFilters/filterConstant.js"
import { mapActions, mapMutations, mapState } from "vuex";
import utils from '@/assets/utils'
import _ from 'lodash'
import helper from "../../../superAdmin/insights/helper";

export default {
  components: {
    LineChart,
    GlobalFilters
  },
  props: {
    tabs: Array,
    byOverTime: Boolean
  },
  computed: {
    ...mapState('analytics', ['chartData','table', 'FILTERS_SELECTED']),
    allTotalCount(){      
      let count = 0;
      const chartDataSets = this.chartData.datasets ? this.chartData.datasets : null
      if(chartDataSets) {
        chartDataSets.forEach(dataSet => {
          dataSet.totalCount.forEach(val => {
              count += val;
          });
        });
      }

      return count
    }    
  },
  data() {
    return {
      categories: [
        filterCategories.ORGANIZATIONS,
        filterCategories.CLINICS,
        filterCategories.NURSE,
        filterCategories.PATIENTS,
        filterCategories.TREATMENTS,
      ],
      selectedCategory: {},
      subCategories: [],
      selectedSubCategory: {},
      selected: {},
      static: {
        temp_brand: [],
      },
      // Chart data
      // chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            labels: {
              boxWidth: 12
            }
          },
          title: {
            display: true,
            text: ''
          },
          tooltip: {
            callbacks: {
              footer: function(context) {
                const contextItem = context[0]
                const totalCount = contextItem.dataset.totalCount 
                let totalCountLabel = "";

                if(totalCount && totalCount.length){
                  totalCountLabel = 'Count: ' + totalCount[contextItem.dataIndex];
                }
                return totalCountLabel;
              },
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: '% Brand Share'
            },
            min: 0,
            max: null,
          },
          x: {
            title: {
              display: true,
              text: "Month - Year"
            },
            grid : {
              display : false
            },
            ticks: {
              font: {
                size: 10
              },
              maxRotation: 90,
              minRotation: 90,
            }
          }
        }
      },
      urlType: "",
      orgId: "",
    }
  },
  methods:{
    ...mapActions("analytics", ["fetchOvertime"]),
    ...mapMutations("analytics", ["MUTATE_CHART_DATA", "MUTATE_TABLE_DATA", "MUTATE_TABLE_HEADERS"]),
    fetchOvertimeChartData() {

      let payload = helper.generateAnalyticsAPIParameters(this.selected)

      if(this.selected.brand && this.selected.brand.length && this.static.temp_brand[0] === 0){
        payload.brand = this.selected.brand
      } else {
          payload.brand = this.static.temp_brand
      }

      if (["OrgOwner", "SuperAdmin"].includes(this.urlType)){
        payload.brand = this.selected.brand
      }

      if(this.byClinic){
        payload = {
          ...payload,
          is_clinic: this.byClinic,
        }
      }
      if(!["SuperAdmin", "Supplier"].includes(this.urlType)){
        payload = {
          ...payload,
          org: [this.orgId],
        }
      }
      this.fetchOvertime(payload).then((res) => {
        const headers= res.data.chartData.datasets.map((data)=>data.label)
        const tableHeaders = ["Month-Year", 
        ..._.pullAll(this.byOverTime? headers : [null],[null])];
        
        this.MUTATE_TABLE_HEADERS(tableHeaders)
        this.MUTATE_CHART_DATA(res.data.chartData)

        const { labels, datasets } = res.data.chartData;
        const tableData = labels.map((label, index) => {
          return {
            brand: label,
            values:this.getvalues(index,datasets),
            percent_treatment: datasets[0].data[index] + ' %',
          }
        })
        this.MUTATE_TABLE_DATA(tableData)
      });
    },
    getvalues(index,datasets){
      const data = datasets.map( brand => {
        const temp={}
          return brand.data[index] + ' %'
        } )
      return data
    }
  },
  created() {
    this.selected = _.cloneDeep(this.FILTERS_SELECTED)
    this.MUTATE_TABLE_DATA([])
    this.MUTATE_CHART_DATA({})
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      this.static.temp_brand = userType.brands;
    } else if(this.urlType == "Supplier" || this.urlType == "OrgOwner"){
      this.selected.brand = []
      this.static.temp_brand[0] = 0
    }
    this.fetchOvertimeChartData()
  },
  watch: {
    selected: {
      handler() {
        this.fetchOvertimeChartData();
      },
      deep: true,
    }
  }
}
</script>

<style ></style>